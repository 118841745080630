var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        "max-width": "1300",
        width: "1300",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "", flat: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-5 pt-4" },
            [
              _vm._v(" Related Promo Groups "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.selected.length === 0,
                  },
                  on: { click: _vm.addGroups },
                },
                [_vm._v(" Add to Feature ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(
              " " +
                _vm._s(_vm.promo.promo_category_name) +
                " | " +
                _vm._s(_vm.promo.name) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.tableHeaders,
                  items: _vm.relatedPromos,
                  dense: "",
                  height: _vm.tableSize,
                  "group-by": "promo_item_group_id",
                  "custom-sort": _vm.customSort,
                  "fixed-header": "",
                  "footer-props": { "items-per-page-options": [-1] },
                  "items-per-page": -1,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `header.select-all`,
                      fn: function () {
                        return [
                          _c("v-checkbox", {
                            staticClass: "my-auto mx-0",
                            attrs: {
                              "hide-details": "",
                              dark: "",
                              disabled: _vm.availableGroupIds.length === 0,
                              ripple: false,
                            },
                            on: { change: _vm.selectAll },
                            model: {
                              value: _vm.allSelected,
                              callback: function ($$v) {
                                _vm.allSelected = $$v
                              },
                              expression: "allSelected",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `group.header`,
                      fn: function ({ group, items, headers }) {
                        return [
                          _c(
                            "td",
                            { attrs: { colspan: headers.length } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _vm.groupIds.some((id) => id === group)
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "success" } },
                                            [_vm._v(" mdi-check ")]
                                          )
                                        : [
                                            _c("v-checkbox", {
                                              staticClass: "my-auto mx-0",
                                              attrs: {
                                                color: "primary",
                                                "hide-details": "",
                                                value: group,
                                                ripple: false,
                                              },
                                              model: {
                                                value: _vm.selected,
                                                callback: function ($$v) {
                                                  _vm.selected = $$v
                                                },
                                                expression: "selected",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c("v-col", { staticClass: "py-4" }, [
                                    _c("div", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(items[0].item_group_name)
                                        ),
                                      ]),
                                    ]),
                                    _c("span", { staticClass: "caption" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(items[0].promo_category_name) +
                                          " "
                                      ),
                                    ]),
                                    _c("span", { staticClass: "caption" }, [
                                      _vm._v(" | "),
                                    ]),
                                    _c("span", { staticClass: "caption" }, [
                                      _vm._v(
                                        " " + _vm._s(items[0].promo_name) + " "
                                      ),
                                    ]),
                                    _c("span", { staticClass: "caption" }, [
                                      _vm._v(" | "),
                                    ]),
                                    _c("span", { staticClass: "caption" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(items[0].start_dt) +
                                          " - " +
                                          _vm._s(items[0].end_dt) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }